/* .testimonial .slick-arrow-slick-prev,
.testimonial .slick-arrow-slick-next {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 10px;
  color: #446ccd;
} */
.slick-prev::before,
.slick-next::before {
  color: #446ccd;
  font-size: 30px;
  /* display: none; */
}

.testimonial {
  background-image: url('../../images/backgroundTestimonios.png');
}
